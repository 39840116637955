<div class="container">
  <!-- <div class="row">
    <div class="col-md-12">
      <h1>Dr Simmed</h1>
    </div>
  </div> -->
  <div class="row g-0 m-4">
    <div class="col-md-6">
      <img src="https://api.simmed.vn/assets/uploads/abouts/3.png" alt="" srcset="" />
    </div>
    <div class="col-md-6">
      <div class="text-center">
        <h2 style="font-size: 36px; font-weight: bold; margin-top: 20px">BỆNH ÁN ĐIỆN TỬ</h2>
        <p>Bạn có thể theo dõi bệnh án điện tử và đặt lịch online qua app Dr Simmed . <br />Quét mã QR hoặc chọn hdh để tải app Dr Simmed</p>
        <div class="d-flex justify-content-center align-items-center">
          <div class="me-3">
            <img style="width: 180px; min-width: 180px" src="/assets/images/qrcode-drsimmed.svg" alt="" srcset="" />
          </div>
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.simmed.idoctor" target="_blank">
              <img style="height: 60px; width: unset" src="/assets/images/googleplay.png" alt="" srcset="" />
            </a>
            <div style="height: 10px; display: block"></div>
            <a href="https://apps.apple.com/us/app/dr-simmed/id6448910148" target="_blank">
              <img style="height: 60px; width: unset" src="/assets/images/appstore.png" alt="" srcset="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
